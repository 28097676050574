import React, { useState, useEffect } from 'react';
import axios from 'axios';

import ini from 'ini';
import dir from './config.ini';
import './TaskTable.css';


const response = await fetch(dir);
// Retrieve the text content of the response
const iniText = await response.text();
const config = ini.parse(iniText);


const TaskTable = ({ fetchIntervalTime }) => {
  const [data, setData] = useState([]);

  const [loads_data, setLoadsData] = useState([]);

  const [truck_count, setTruckCount] = useState([]);

  const [driver_name_list, setDriverNameList] = useState([]);

  const [driver_assigns, setDriverAssigns] = useState([]);

  const [driver_called_back, setDriverCalledBack] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(global.ipAddress);
        setData(response.data.sheet_data);
        setLoadsData(response.data.loads_data);
        setTruckCount(response.data.truck_count);
        setDriverNameList(response.data.driver_name_list);
        setDriverAssigns(response.data.driver_assigns);
        setDriverCalledBack(response.data.driver_called_back);
      } catch (error) {
        console.error('Error fetching data:', error);
        setTruckCount(-1);
        setData(-1);
        setLoadsData(-1);
        setDriverNameList(-1);
        setDriverAssigns(-1);
        setDriverCalledBack(-1);
      }


    };

    // Fetch data immediately when the component mounts
    fetchData();

    // Fetch data every 30 seconds after the initial fetch
    const fetchInterval = setInterval(() => {
      fetchData();
    }, fetchIntervalTime); 

    // Clear the interval on component unmount
    return () => {
      clearInterval(fetchInterval);
    };
  }, []);


  const handleCalledBackButton = (truck_id) => {
    const updatedCalledBack = { ...driver_called_back };

    if (updatedCalledBack[truck_id] === true){
      throw new Error("CalledBackButton shouldn't be allowed to be unpressed");
    }

    setDriverCalledBack(updatedCalledBack);
    
     axios.post(global.ipAddress + 'trigger_callback', {
      id: truck_id
    })
    
    .then(response => {
        // Handle response
        console.log(response.data);
        updatedCalledBack[truck_id] = true;
    })
    .catch(err => {
        // Handle errors
        console.error(err);
    });
  };

  const handleTextButton = (rowIndex, key) => {
    const updatedData = [...data];

    if (updatedData[rowIndex][key].isChecked === true){
      throw new Error("TextButton shouldn't be allowed to be unpressed");
    }
    updatedData[rowIndex][key].isChecked = true;
    setData(updatedData);

     axios.post(global.ipAddress + 'update_checkbox_state', {
      rowIndex,
      key,
      isChecked: updatedData[rowIndex][key].isChecked
    })
    .then(response => {
        // Handle response
        console.log(response.data);
    })
    .catch(err => {
        // Handle errors
        console.error(err);
    });
  };

  const renderDropDown = (truckid ,options, selected) => {
    selected = driver_assigns[truckid];
    // Function to update the state when a new option is selected
    const handleDropDownChange = (event) => {
      console.log(truckid);
      console.log(event.target.value);
      const updatedAssigns = { ...driver_assigns };
      updatedAssigns[truckid] = event.target.value;

      axios.post(global.ipAddress + 'update_driver_assigns', {
        id:truckid,
        value:event.target.value
      })
      
      .then(response => {
          // Handle response
          console.log(response.data);
          selected = driver_assigns[truckid]
          setDriverAssigns(updatedAssigns);

      })
      .catch(err => {
          // Handle errors
          console.error(err);
      });

    };

    return (
      <select style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}} value={selected} onChange={handleDropDownChange}>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    );

  };

  const renderCell = (rowIndex, key, value) => {
    const isChecked = value.isChecked;
    //change cellcolor when is checked is true
    let cellcolor = '';
    if (isChecked === true){
      cellcolor = '#90EE90'
    }

    if(!(value.data === null)){
      return (
        <div style={{ backgroundColor: cellcolor }}>
          <input
          type="button"
          value="TEXT"
          disabled={isChecked}
          onClick={() => handleTextButton(rowIndex, key)}
          />
          <>
            <div>Warehouse: {value.data[0]}</div>
            <div>Load: {value.data[1]}</div>
            <div>Travel Time: {value.data[2]}</div>
          </>

        
        </div>
      
      
        );

    }else{
      return (
        <></>
      )

    }
  };
  const displayTruckCount = () => {
    if (truck_count === -1) {
      return <p>Loading Truck Count...</p>;
    } else {
      return <p>Current Truck Count is: {truck_count}</p>;
    }
  };

  const renderTable = () => {
    if (data === -1) {
      return <p>Loading...</p>;
    }

    if (data.length === 0) {
      return <p>No Loads</p>;
    }
  
    const columns = Object.keys(data[0]);
    let loads_list = [];
    // populate the loads list with loads data, this will be map-rendered onto the first row of the table
    for (let i = 0; i < columns.length; i++) {
      loads_list.push(loads_data[columns[i]]);
    }

    let drivers_list = [];
    for (let i = 0; i < columns.length; i++) {
      drivers_list.push(driver_assigns[columns[i]]);
    }

  
    return (
      <table>
        <tbody>
          <tr>
            {columns.map((column, index) => (
              <td key={column} style={{ width: '100px', textAlign: 'center' }}>
                {renderDropDown(column, driver_name_list, drivers_list[index])}
              </td>
            ))}
          </tr>
          <tr>
            {columns.map((column, index) => (
              <td key={column} style={{ width: '100px', textAlign: 'center' }}>
                {loads_list[index]}
              </td>
            ))}
          </tr>
          <tr>
            {columns.map((column) => (
              <th key={column} style={{
                width: '100px',
                textAlign: 'center',
                backgroundColor: driver_called_back[column] ? '#ADD8E6' : '' // Change to 'blue' if true, otherwise ''
              }}>
                {column}

                <input
                style={{ marginLeft: '10px' }}
                type="button"
                value="CALL BACK"
                disabled={driver_called_back[column]}
                onClick={() => handleCalledBackButton(column)}
                />
              </th>
            ))}
          </tr>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} style={{ height: '200px' }}>
              {columns.map((column) => (
                <td key={column} style={{ width: '100px', textAlign: 'left' ,verticalAlign: 'top'}}>
                  {renderCell(rowIndex, column, row[column])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
    
  };

  return (
    <div>
      <h1>Miami pickups project</h1>
      {displayTruckCount()}
      <h2>Truck Recommendations</h2>
      {renderTable()}
    </div>
  );
};

export default TaskTable;
