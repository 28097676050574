import React, { useState, useEffect }  from 'react';
import TaskTable from './components/TaskTable';
import LoadsTable from './components/LoadsTable';
import WarehouseTable from './components/WarehouseTable';

global.ipAddress = 'http://127.0.0.1:5000/'
console.log(process.env)
const envVar = process.env.REACT_APP_FLASK_IP;
if (envVar){
  global.ipAddress = envVar;
  console.log("REACT_APP_FLASK_IP found in environment var, using...")
}else{
  console.log("USING LOCAL IP ADDRESS for server")
}

function App() {
  // polling interval in milliseconds
  const fetchIntervalTime = 5 * 1000

  return (
    <div>
      <TaskTable fetchIntervalTime={fetchIntervalTime}/>
    </div>
  );
}

export default App;
