// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add your custom styles for the WarehouseTable component here */

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.first-column {
  width: 1%; /* or set a specific width in px or em */
  white-space: nowrap; /* Prevents text from wrapping */
}

.uniform-width {
  width: 100px; /* You can adjust this value as per your requirement */
}


#sum {
  background-color: #C7C6C1;
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f5f5f5;
}

h2 {
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/WarehouseTable.css"],"names":[],"mappings":"AAAA,iEAAiE;;AAEjE;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,SAAS,EAAE,wCAAwC;EACnD,mBAAmB,EAAE,gCAAgC;AACvD;;AAEA;EACE,YAAY,EAAE,sDAAsD;AACtE;;;AAGA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["/* Add your custom styles for the WarehouseTable component here */\n\ntable {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 20px;\n}\n\nth, td {\n  border: 1px solid #ddd;\n  padding: 8px;\n  text-align: left;\n}\n\n.first-column {\n  width: 1%; /* or set a specific width in px or em */\n  white-space: nowrap; /* Prevents text from wrapping */\n}\n\n.uniform-width {\n  width: 100px; /* You can adjust this value as per your requirement */\n}\n\n\n#sum {\n  background-color: #C7C6C1;\n}\n\nth {\n  background-color: #f2f2f2;\n}\n\ntr:hover {\n  background-color: #f5f5f5;\n}\n\nh2 {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
