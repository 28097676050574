import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './WarehouseTable.css';

const WarehouseTable = ({ fetchIntervalTime }) => {
  const [warehouseData, setWarehouseData] = useState({ columns: [], data: [], index: [] });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(global.ipAddress);
        setWarehouseData(response.data.warehouse_data);
      } catch (error) {
        console.error('Error fetching warehouse data:', error);
      } finally {
        setLoading(false);
      }
    };

    // Fetch data immediately when the component mounts
    fetchData();

    // Fetch data every 30 seconds after the initial fetch
    const fetchInterval = setInterval(() => {
      fetchData();
    }, fetchIntervalTime); 

    // Clear the interval on component unmount
    return () => {
      clearInterval(fetchInterval);
    };
  }, []);

  const calculateColumnSum = (columnIndex) => {
    let sum = warehouseData.data.reduce((acc, row) => acc + row[columnIndex], 0)
    sum = parseFloat(sum.toFixed(2))
    return sum;
  };

  if (loading){
    return (
      <div>
        <h2>Warehouse Data</h2>
        <p>Loading...</p>
      </div>
      );
  }

  if (warehouseData.data.length === 0){
    return (
      <div>
        <h2>Warehouse Data</h2>
        <p>No Loads</p>
      </div>
      );
  }
  return (
    <div>
      <h2>Warehouse Data</h2>
      <table>
        <thead>
          <tr>
            <th class="first-column">Destinations</th>
            {warehouseData.columns.map((column, index) => (
              <th key={index} >{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {warehouseData.index.map((rowName, rowIndex) => (
            <tr key={rowIndex}>
              <td class="first-column">{rowName}</td>
              {warehouseData.data[rowIndex].map((cellData, cellIndex) => (
                <td key={cellIndex} className="uniform-width">{cellData}</td>
              ))}
            </tr>
          ))}
          
          <tr bgcolor="yellow">
            <td id="sum" style={{ minWidth: 'max-content' }}>
              <b>Sum</b>
            </td>
            {warehouseData.columns.map((column, columnIndex) => (
              <td id="sum" key={columnIndex} className="uniform-width"> 
                {calculateColumnSum(columnIndex)}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
  
};

export default WarehouseTable;