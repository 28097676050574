import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LoadsTable.css';

const LoadsTable = ({ fetchIntervalTime }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(global.ipAddress);
        setData(response.data.loads_data);
      } catch (error) {
        console.error('Error fetching loads data:', error);
      }
    };

    // Fetch data immediately when the component mounts
    fetchData();

    // Fetch data every 30 seconds after the initial fetch
    const fetchInterval = setInterval(() => {
      fetchData();
    }, fetchIntervalTime); 

    // Clear the interval on component unmount
    return () => {
      clearInterval(fetchInterval);
    };
  }, []);

  const renderTable = () => {
    if (data.length === 0) {
      return <p>Loading...</p>;
    }

    const keys = Object.keys(data[0]);

    return (
      <table>
        <thead>
          <tr>
            {keys.map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {keys.map((key) => (
                <td key={key}>{row[key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <h2>Loads Data</h2>
      {renderTable()}
    </div>
  );
};

export default LoadsTable;
